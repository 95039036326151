<template>
  <v-sheet class="pa-3">
    <v-row>
      <v-col>
        <span class="text-h4">Retrouvez nos partenaires :</span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-for="(partner, index) in partners" :key="index" cols="6">
        <v-card outlined>
          <v-img :src="partner.img" height="200px"></v-img>
          <v-card-title v-text="partner.title"></v-card-title>
          <v-card-text class="text-body-2" v-text="partner.desc"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon :href="partner.href" target="_blank">
              <v-icon>mdi-share-variant</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "produits",
  data: () => ({
    partners: [
      {
        title: 'NEGOC\'PAYSAGE',
        href: 'https://www.negocpaysage.com/',
        img: require('@/assets/np.jpg'),
        desc: 'Dallages, Pavés, Boiseries Extérieur et Mobiliers de Jardin.'
      },
      {
        title: 'Transport Rouxel',
        href: 'https://www.rouxel.fr',
        img: require('@/assets/rxl.jpg'),
        desc: 'Transport de matériaux'
      }
    ]
  }),
  computed: {},
  methods: {},
};
</script>
